import {
  useOperatorByIdLazyQuery,
  useProductByIdLazyQuery,
  useBookerByIdLazyQuery,
} from "gql/generated";
import { useEffect, useState } from "react";
import { BookerContextValues } from "./BookerContext";

export const useGetBookerData = (bookerID: string, isModalOpen: boolean) => {
  const [booker, setBooker] = useState<BookerContextValues["booker"]>(null);
  const [product, setProduct] = useState<BookerContextValues["product"]>(null);
  const [operator, setOperator] =
    useState<BookerContextValues["operator"]>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [getBooker] = useBookerByIdLazyQuery();
  const [getProduct] = useProductByIdLazyQuery();
  const [getOperator] = useOperatorByIdLazyQuery();

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const { data: bookerData, error: bookerError } = await getBooker({
        variables: { id: bookerID },
      });

      if (!bookerData?.bookerById || bookerError) {
        setError(true);
        return;
      }

      setBooker(bookerData.bookerById as BookerContextValues["booker"]);

      const productID =
        bookerData?.bookerById?.productBundles?.[0]?.product || "";
      const operatorID = bookerData?.bookerById?.operator || "";

      if (productID) {
        await getProduct({ variables: { id: productID } })
          .then(({ data: productData }) => {
            if (productData?.productById) {
              setProduct(
                productData.productById as BookerContextValues["product"],
              );
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true));
      }

      if (operatorID) {
        await getOperator({ variables: { id: operatorID } })
          .then(({ data: operatorData }) => {
            if (operatorData?.operatorById) {
              setOperator(
                operatorData.operatorById as BookerContextValues["operator"],
              );
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true));
      }
      setLoading(false);
    }

    getData();
    // Include isModalOpen in dependancies to trigger getData() on open
    // This is to update any out of date data
  }, [getBooker, bookerID, getProduct, getOperator, isModalOpen]);

  return {
    product,
    error,
    operator,
    loading,
    booker,
  };
};
