import {
  Stripe,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeElements,
} from "@stripe/stripe-js";
import { useCartContext } from "apps/custom/ttc/booker/contexts";

export const usePaymentMethod = () => {
  const { updateStripeCardElement, stripeCardElement, updatePaymentMethodID } =
    useCartContext();

  const handleCardElementChange = async (
    e: StripeCardElementChangeEvent,
    card?: StripeCardElement,
    elements?: StripeElements,
    stripeObj?: Stripe | null,
  ) => {
    if (e.complete && card) {
      updateStripeCardElement(card);

      if (elements && stripeObj) {
        await elements.submit();
        const { error, paymentMethod } = await stripeObj.createPaymentMethod({
          type: "card",
          card,
        });
        if (paymentMethod && !error) updatePaymentMethodID(paymentMethod.id);
      }
    } else {
      updateStripeCardElement(undefined);
      updatePaymentMethodID("");
    }
  };

  return { onChange: handleCardElementChange, isComplete: !!stripeCardElement };
};
