import format from "date-fns/format";
import { addDays, addWeeks, parse } from "date-fns";
import isNil from "lodash/isNil";
import { parseDate } from "./parseDate";

export const DATE_PATTERN_STANDARD = "LLLL d, yyyy";
export const DATE_PATTERN_STANDARD_WEEKDAY = "EEE, LLLL d, yyyy";
export const DATE_PATTERN_MED = "LLLL d";
export const DATE_PATTERN_SHORT = "LLL. d";
export const DATE_PATTERN_TIME = "h:mmaaa";
export const DATE_PATTERN_DATE_TIME_INPUT = "yyyy-MM-dd'T'HH:mm";
export const DATE_PATTERN_MONTH = "MMMM";
export const DATE_PATTERN_YEAR = "yyyy";
export const DATE_PATTERN_DAY = "d";
export const DATE_PATTERN_SIMPLE = "yyyy-MM-dd";
export const DATE_PATTERN_FULL_DAY = "MMMM dd";

export function formatDate(
  date: string | Date | undefined,
  pattern = DATE_PATTERN_STANDARD,
) {
  if (!date) return "";
  const dateObj = typeof date === "string" ? parseDate(date) : date;
  return format(dateObj, pattern, { weekStartsOn: 1 });
}

export const formatUnix = (date: number, pattern = "MMM dd, yyyy") => {
  if (!date) return "";
  return format(new Date(date * 1000), pattern);
};

export const SectionDate = ({
  year,
  month,
  week,
}: {
  year?: number | null;
  month?: number | null;
  week?: number | null;
}) => {
  if (!year) return "";
  let formattedDate = "";
  if (month) {
    const date = parse(`${year}-${month}`, "y-M", new Date());
    formattedDate = format(date, "MMMM");
  }

  if (!isNil(week)) {
    const date = addWeeks(new Date(String(year)), week);
    formattedDate = format(date, "MMMM dd");
    formattedDate = formattedDate.concat(
      " - ",
      format(addDays(date, 6), "MMMM dd"),
    );
  }
  return formattedDate;
};
