import { Button, Heading, Text } from "@ttc3k/trekker";
import { Mail } from "iconoir-react";
import { Box, Flex } from "styled-system/jsx";
import { CollapsableText } from "booker/shared";
import { useBookerContext } from "booker/contexts";
import _ from "lodash";
import { useState } from "react";
import { ContactUsPage } from "../OverlayPages";

export const OperatorDetails = () => {
  const [contactPageOpen, setContactPageOpen] = useState(false);

  const { product, operator, booker } = useBookerContext();
  const operatorAddress = operator?.contact.address
    ? _.compact([
        operator.contact.address.line1,
        operator.contact.address.line2,
        operator.contact.address.city,
        operator.contact.address.subdivision,
        operator.contact.address.postalCode,
        operator.contact.address.country,
        operator.contact.address.location?.coordinates[0]?.toString(),
        operator.contact.address.location?.coordinates[1]?.toString(),
      ]).join(", ")
    : "";
  return (
    <Flex flexDir={"column"} gap={"300"}>
      <Heading size={"h3"} color={"text.dark"}>
        {product?.name.en}
      </Heading>
      <Box
        width={"full"}
        borderBottomWidth={"1px"}
        borderBottomColor={"border.light"}
      />
      <Flex flexDir={"column"} gap={"50"}>
        <Text visual={"bodySemiBold"} color={"text.mid"}>
          {operator?.name}
        </Text>
        <Text visual={"smallMedium"} color={"text.light"}>
          {operatorAddress}
        </Text>
      </Flex>
      <Button
        Icon={Mail}
        isIconRightAligned={false}
        size={"lg"}
        visual={"outline"}
        onClick={() => setContactPageOpen(true)}
      >
        Contact us
      </Button>
      <CollapsableText text={product?.description.en ?? ""} maxHeight={150} />
      <ContactUsPage
        open={contactPageOpen}
        onClose={() => setContactPageOpen(false)}
        shadowRootIdSuffix={booker?._id}
      />
    </Flex>
  );
};
