import * as stripe from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";
import { token } from "styled-system/tokens";
import { styled } from "styled-system/jsx";

type StripeCardElementProps = {
  onChange?: (
    payload: stripe.StripeCardElementChangeEvent,
    card?: stripe.StripeCardElement,
    elements?: stripe.StripeElements,
    stripeObj?: stripe.Stripe | null,
  ) => void;
  connectedAccountId: string;
};

/**
 * Stripe passes these styles to their components in an iframe
 * We must use non-semantic tokens to ensure the appropriate HEX values are
 * used here rather than css variables.
 * We also must use 'px' for sizes instead of 'rem'
 */
const inputStyle = {
  fontFamily: "'Figtree', sans-serif",
  fontWeight: token("fontWeights.normal"),
  fontSize: "16px",
  lineHeight: "28px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: token("colors.orange.600"),
  },
  "::placeholder": {
    color: token("colors.ink.400"),
  },
  "::selection": {
    color: token("colors.blue.500"),
  },
};

/**
 * These are styles applied to the stripe element outside of the iframe
 */
const StripeWrapper = styled("div", {
  base: {
    "& > .StripeElement--focus": {
      borderColor: "border.dark!",
      boxShadow: "0 0 0 1px token(colors.border.dark)",
      _hover: {
        borderColor: "border.dark!",
        boxShadow: "0 0 0 1px token(colors.border.dark)!",
      },
    },
    "& > .StripeElement--invalid": {
      borderColor: "border.error!",
      boxShadow: "0 0 0 1px token(colors.border.error)",
      _hover: {
        borderColor: "border.error!",
        boxShadow: "0 0 0 1px token(colors.border.error)!",
      },
    },
    "& > .StripeElement": {
      borderWidth: "1px",
      borderColor: "border.light",
      borderRadius: "100",
      px: "150",
      py: "100",
      bg: "white",
      color: "text.dark",
      fontFamily: "body",
      fontSize: "md",
      lineHeight: "xs",
      width: "full",
      transitionDuration: "normal",
      transitionProperty: "box-shadow, border-color",
      transitionTimingFunction: "default",
      _autofill: {
        color: "text.warning.mid",
      },
      _placeholder: {
        color: "text.accent.mid",
      },
      _selection: {
        color: "text.accent.mid",
      },
      _hover: {
        borderColor: "border.mid",
        boxShadow: "0 0 0 1px token(colors.border.mid)",
      },
    },
  },
});

export const StripeCardElement = ({
  onChange,
  connectedAccountId,
}: StripeCardElementProps) => {
  const [isError, setIsError] = useState(false);
  const stripeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadStripeElement = async () => {
      if (
        !import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
        !connectedAccountId ||
        connectedAccountId.length < 1
      ) {
        throw new Error(
          "Missing stripe publishable key or connected account id",
        );
      }
      if (stripeRef.current) {
        const stripeElement = document.createElement("div");
        const stripeElementId = "booker-stripe-element-wrapper";
        stripeElement.id = stripeElementId;
        try {
          const stripeObj = await stripe.loadStripe(
            import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            {
              stripeAccount: connectedAccountId,
            },
          );
          const elements = stripeObj?.elements();
          const cardElement = elements?.create("card", {
            disableLink: true,
            style: { base: inputStyle },
          });

          cardElement?.on("change", async e => {
            onChange?.(e, cardElement, elements, stripeObj);
          });
          cardElement?.mount(stripeElement);

          const isStripeElementAppended = stripeRef.current.querySelector(
            "#" + stripeElementId,
          );
          if (!isStripeElementAppended) {
            stripeRef.current.appendChild(stripeElement);
          }
        } catch {
          setIsError(true);
        }
      }
    };
    loadStripeElement();
  }, [connectedAccountId, onChange]);

  if (isError) {
    throw new Error("Error attempting to load stripe");
  }

  return <StripeWrapper ref={stripeRef} />;
};
