import { PurchasedProductGuestsPerTier } from "gql/generated";
import { getTotalGuestCount } from "booker/shared/utils";
import { useBookerContext } from "booker/contexts";
import { Box, Flex } from "styled-system/jsx";
import { Text } from "@ttc3k/trekker";
import { ConsumingOverlayPageProps, OverlayPage } from "../OverlayPage";
import { GuestNames } from "./GuestNames";
import { GuestCounts } from "./GuestCounts";

type GuestsPageProps = ConsumingOverlayPageProps & {
  guests: PurchasedProductGuestsPerTier[];
  guestNames: string[];
  onUpdateGuests: (g: PurchasedProductGuestsPerTier) => void;
  onUpdateGuestNames: (name: string, index: number) => void;
  completeGuests: () => void;
  resetGuests: () => void;
};

export const GuestsPage = ({
  guests,
  onUpdateGuests,
  guestNames,
  onUpdateGuestNames,
  completeGuests,
  onClose,
  resetGuests,
  ...rest
}: GuestsPageProps) => {
  const { product, booker } = useBookerContext();
  const guestMetadata = product?.accommodationMetadata?.guestMetadata;

  const isGuestCtAboveMinimum =
    getTotalGuestCount(guests, product?.pricing.tiers, true) >=
    (guestMetadata?.minimumNumber ?? 0);
  const isGuestCtBelowMaximum = guestMetadata?.maximumNumber
    ? getTotalGuestCount(guests, product?.pricing.tiers, true) <=
      guestMetadata?.maximumNumber
    : true;

  const isGuestCtValid = isGuestCtAboveMinimum && isGuestCtBelowMaximum;
  const isGuestNamesValid = guestMetadata?.nameEntryRequired
    ? guestNames.every(v => v.length > 0)
    : true;

  const isGuestPageValid = isGuestCtValid && isGuestNamesValid;

  const handleButtonClick = () => {
    completeGuests();
    onClose();
  };

  const handleBackClick = () => {
    resetGuests();
    onClose();
  };
  return (
    <OverlayPage
      title={"Guests"}
      subtitle={
        "Please select your guests below, and enter the names of everyone in your group."
      }
      buttonDisabled={!isGuestPageValid}
      onButtonClick={handleButtonClick}
      onClose={handleBackClick}
      shadowRootIdSuffix={booker?._id}
      {...rest}
    >
      <Flex flexDir={"column"} py={"400"} gap={"800"}>
        <Box>
          <GuestCounts
            guests={guests}
            onUpdateGuests={onUpdateGuests}
            priceTiers={product?.pricing.tiers ?? []}
          />
          {(!isGuestCtAboveMinimum || !isGuestCtBelowMaximum) && (
            <Text visual={"smallMedium"} color={"text.warning.mid"}>
              Please select a total number of guests between&nbsp;
              {guestMetadata?.minimumNumber}-{guestMetadata?.maximumNumber}
            </Text>
          )}
        </Box>
        <GuestNames
          guestNames={guestNames}
          onUpdateGuestNames={onUpdateGuestNames}
        />
      </Flex>
    </OverlayPage>
  );
};
