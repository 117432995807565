import { useState } from "react";
import { StyleRoot } from "@ttc3k/trekker";
import { MatomoAnalyticsContextProvider } from "@ttc3k/ttc-bkr-analytics";
import styles from "src/index.css?inline";
import { SingleProductModal } from "./features/SingleProductModal";
import { BookerContextProvider, CartContextProvider } from "./contexts";
import { StyleMap } from "./shared/utils";
import "./booker-button.css";

export type BookerPosition = "left" | "right";

export type BookerProps = {
  bookerID: string;
  buttonBgColor?: string;
  buttonCopy?: string;
  buttonStyle?: StyleMap;
  isEmbedding?: boolean;
  bookerPosition?: BookerPosition;
};

type BookerModalProps = {
  bookerID: string;
  open: boolean;
  onClose: () => void;
  bookerPosition: BookerPosition;
};

function BookerModal({
  bookerID,
  open,
  onClose,
  bookerPosition,
}: BookerModalProps) {
  const analyticsEnabled = import.meta.env.VITE_ENVIRONMENT == "production";

  return (
    <MatomoAnalyticsContextProvider
      containerId="Xu6ohsle"
      enabled={analyticsEnabled}
    >
      <BookerContextProvider
        isModalOpen={open}
        onCloseBookerModal={onClose}
        bookerID={bookerID}
      >
        <CartContextProvider>
          <SingleProductModal open={open} bookerPosition={bookerPosition} />
        </CartContextProvider>
      </BookerContextProvider>
    </MatomoAnalyticsContextProvider>
  );
}

function Booker({
  bookerID,
  buttonBgColor,
  buttonCopy = "Book now",
  buttonStyle,
  isEmbedding = false,
  bookerPosition = "right",
}: BookerProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <button
        className="ttc-booker-button-default"
        onClick={() => setIsModalOpen(true)}
        style={{
          ...buttonStyle,
          backgroundColor: buttonBgColor
            ? buttonBgColor
            : buttonStyle?.["background-color"]
              ? buttonStyle?.["background-color"].toString()
              : undefined,
        }}
      >
        {buttonCopy}
      </button>
      {isEmbedding ? (
        <StyleRoot styles={[styles]} idSuffix={bookerID} portal>
          <BookerModal
            bookerID={bookerID}
            onClose={() => setIsModalOpen(false)}
            open={isModalOpen}
            bookerPosition={bookerPosition}
          />
        </StyleRoot>
      ) : (
        <BookerModal
          bookerID={bookerID}
          onClose={() => setIsModalOpen(false)}
          open={isModalOpen}
          bookerPosition={bookerPosition}
        />
      )}
    </>
  );
}

export { Booker, BookerModal };
