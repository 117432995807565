import { Modal } from "@ttc3k/trekker";
import { ErrorBoundary } from "@sentry/react";
import { formatPrice } from "core/utils/formatPrice";
import { PageError } from "../PageError";
import { useBookerContext, useCartContext } from "../../contexts";
import { BookerPosition } from "../../App";
import { SingleProductModalContent } from "./SingleProductModalContent";

type SingleProductModalProps = {
  open: boolean;
  bookerPosition: BookerPosition;
};

export const SingleProductModal = ({
  open: isOpen,
  bookerPosition,
}: SingleProductModalProps) => {
  const { resetCartValuesToDefault, grandTotal } = useCartContext();
  const { onCloseBookerModal } = useBookerContext();

  const handleCloseModal = () => {
    resetCartValuesToDefault();
    onCloseBookerModal();
  };

  return (
    <Modal
      open={isOpen}
      placement={bookerPosition === "right" ? "rightPane" : "leftPane"}
      onOpenChange={handleCloseModal}
      tagText={grandTotal > 0 ? `$${formatPrice(grandTotal)}` : undefined}
    >
      <ErrorBoundary fallback={<PageError />}>
        <SingleProductModalContent />
      </ErrorBoundary>
    </Modal>
  );
};
